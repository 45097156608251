@use '@northfork/branding/colors';
@use '@northfork/branding/variables' as vars;
@use '@northfork/branding/mixins';

$card-width: 600px;
$bottom-margin: 20px;

.page--404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - #{vars.$clearwater-header-height});
  width: 100vw;

  background: linear-gradient(180deg, white, colors.get(gray-100));

  .placeholder {
    flex-grow: 1;
  }

  .card {
    margin: 15px;
    flex-grow: 0;
    flex-shrink: 1;

    min-width: $card-width;
    width: $card-width;
    border: 0;
    border-radius: 0;
    box-shadow: 0 3px 12px #00000029;

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .not-found-icon {
      margin: $bottom-margin 0;
    }

    .title {
      color: colors.get(blue);
      font-size: mixins.nf-font-size(C);
      margin-bottom: $bottom-margin;
    }

    .message,
    .button-container {
      padding-bottom: $bottom-margin;
    }

    .button-container {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    .btn-nf .icon-container {
      height: 100%;
      width: vars.$button-padding;
      display: flex;
      align-items: center;
      justify-items: center;
      position: absolute;
      left: 0;
      top: 0;
      svg {
        width: vars.$button-padding;
        height: 17px;
      }
    }
  }
}
