/* autoprefixer grid: on */
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

@use './northfork-react';
@use '@northfork/branding/mixins';
@use '@northfork/branding/variables' as vars;

@import '@northfork/branding/fonts';

* > * {
  box-sizing: border-box;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 1.4;
  @include mixins.nf-font-size(E);
  overflow-x: hidden;

  display: flex;
  height: 100vh;
  flex-direction: column;
  #am-header {
    flex: 0 0 vars.$clearwater-header-height;
  }
  .whole-layout,
  .whole-layout > * {
    display: flex;
    flex: 1 0 calc(100vh - #{vars.$clearwater-header-height});
  }
}
